/* eslint-disable no-use-before-define */
import React, { useEffect } from 'react';
import { useLocation, matchPath } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  Hidden,
  Link,
  List,
  ListSubheader,
  Typography,
  makeStyles
} from '@material-ui/core';
import {
  User as UserIcon,
  PieChart as PieChartIcon,
  Users as UsersIcon,
  Coffee as CoffeeIcon,
  Briefcase as BriefcaseIcon
} from 'react-feather';
import VideogameAssetIcon from '@material-ui/icons/VideogameAsset';
import Logo from 'src/components/Logo';
import useAuth from 'src/hooks/useAuth';
import NavItem from './NavItem';

const sections = [
  {
    subheader: 'Quản lý',
    items: [
      {
        title: 'Vai trò',
        icon: UsersIcon,
        href: '/hbon/management/roles'
      },{
        title: 'Users',
        icon: UsersIcon,
        href: '/hbon/management/users'
      },{
        title: 'Mentor',
        icon: UsersIcon,
        href: '/hbon/management/mentors'
      },{
        title: 'Teacher',
        icon: UsersIcon,
        href: '/hbon/management/teachers'
      },
      {
        title: 'Nội dung',
        icon: UsersIcon,
        href: '/hbon/management/contents'
      },
      {
        title: 'Thẻ thành viên',
        icon: CoffeeIcon,
        href: '/hbon/management/products'
      },
      {
        title: 'Trường học',
        icon: BriefcaseIcon,
        href: '/hbon/management/schools',
        items: [
          {
            title: 'Danh sách trường học',
            href: '/hbon/management/schools'
          },

        ]
      },{
        title: 'Quảng cáo',
        icon: VideogameAssetIcon,
        href: '/hbon/management/ads'
      },
      {
        title: 'Send Notification',
        icon: VideogameAssetIcon,
        href: '/hbon/management/sendnotify/list'
      },
      {
        title: 'Thông báo live ',
        icon: VideogameAssetIcon,
        href: '/hbonctv/management/livecontents'
      },
      {
        title: 'Hẹn giờ nội dung ',
        icon: VideogameAssetIcon,
        href: '/hbon/management/pre_contents'
      },{
        title: 'H5p',
        icon: BriefcaseIcon,
        href: '/hbon/management/h5p'
      },{
        title: 'Event',
        icon: BriefcaseIcon,
        href: '/hbon/management/events'
      },{
        title: 'Teacher',
        icon:UserIcon,
        href: '/teacher/management/classes'
      }


    ]
  },
  // {
  //   subheader: 'Ứng dụng',
  //   items: [
  //     {
  //       title: 'Mail',
  //       href: '/app/mail',
  //       icon: MailIcon
  //     },
  //     {
  //       title: 'Chat',
  //       href: '/app/chat',
  //       icon: MessageCircleIcon,
  //       info: () => (
  //         <Chip
  //           color="secondary"
  //           size="small"
  //           label="Updated"
  //         />
  //       )
  //     },
  //     {
  //       title: 'Lịch',
  //       href: '/app/calendar',
  //       icon: CalendarIcon,
  //       info: () => (
  //         <Chip
  //           color="secondary"
  //           size="small"
  //           label="Updated"
  //         />
  //       )
  //     }
  //   ]
  // },
];

function renderNavItems({
  items,
  pathname,
  depth = 0
}) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, pathname, depth }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({
  acc,
  pathname,
  item,
  depth
}) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        key={key}
        title={item.title}
      />
    );
  }

  return acc;
}

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  }
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();
  const { user } = useAuth();
  const roles = user.roles;
  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const content = (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
    >
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Hidden lgUp>
          <Box
            p={2}
            display="flex"
            justifyContent="center"
          >
            <RouterLink to="/">
              <Logo />
            </RouterLink>
          </Box>
        </Hidden>
        <Box p={2}>
          <Box
            display="flex"
            justifyContent="center"
          >
            <RouterLink to="/app/account">
              <Avatar
                alt="User"
                className={classes.avatar}
                src={user.avatar}
              />
            </RouterLink>
          </Box>
          <Box
            mt={2}
            textAlign="center"
          >
            <Link
              component={RouterLink}
              to="/app/account"
              variant="h5"
              color="textPrimary"
              underline="none"
            >
              {user.name}
            </Link>
            <Typography
              variant="body2"
              color="textSecondary"
            >
              Vai trò:
              {' '}
              {roles==null?"unknown":roles.map((role)=>(
              <Link key={role}
                component={RouterLink}
                to="/hbon"
              >

                  {role}<br/>

              </Link>
              ))
              }
            </Typography>
          </Box>
        </Box>
        <Divider />
        <Box p={2}>
          {sections.map((section) => (
            <List
              key={section.subheader}
              subheader={(
                <ListSubheader
                  disableGutters
                  disableSticky
                >
                  {section.subheader}
                </ListSubheader>
              )}
            >
              {renderNavItems({
                items: section.items,
                pathname: location.pathname
              })}
            </List>
          ))}
        </Box>
        <Divider />
        <Box p={2}>
          <Box
            p={2}
            borderRadius="borderRadius"
            bgcolor="background.dark"
          >
            <Typography
              variant="h6"
              color="textPrimary"
            >
              Trợ giúp?
            </Typography>
            <Link
              variant="subtitle1"
              color="secondary"
              component={RouterLink}
              to="/docs"
            >
              Xem tài liệu hướng dẫn
            </Link>
          </Box>
        </Box>
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default NavBar;
