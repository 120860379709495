import React from 'react';

const Logo = (props) => {
  return (
    <img
      alt="Logo"
      className="hbonlogo"
      src="/hbon/LogoHBON.png"
      {...props}
    />
  );
}

export default Logo;
