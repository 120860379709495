import React from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuth from '../hooks/useAuth';
const TeacherGuard = ({ children }) =>  {
  console.log("start useAuth")
  const { isAuthenticated, user, logout } = useAuth();
  if (!isAuthenticated) {
    let str = window.location.hash;
    const search = str.substring(str.indexOf("?") + 1);

    let params = new URLSearchParams(search);
    
    let token = params.get('fbtoken');
    console.log("wait to check token")
    if(typeof(token)!="undefined" && token !=null){
      console.log(token)
    } else {
      return <Redirect to="/login"/>;
    }
  }
  console.log(isAuthenticated)
  console.log("teacher guard:",user)
  if (user && user.roles && user.roles.some(el => (el === "hbonadmin" || el === 'teacher'))) {
    
    return (
      <>
        {children}
      </>
    );
  } else if (user && "id_token" in user){
    //TODO verify token 
    var param = {
      token: user.id_token
    };
    console.log(param)
    //renew idToken
    // axios.post(`${API_URL}/api/renewToken`,param);
    
    return (
      <>
        {children}
      </>
    );
  }
  
  else {
    console.log('role error');
    return <Redirect to="/404"/>;
  }

};

TeacherGuard.propTypes = {
  children: PropTypes.node
};

export default TeacherGuard;
