import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { firebaseConfig } from 'src/config';
import { initializeApp } from 'firebase/app';
console.log(firebase.apps.length);
if (!firebase.apps.length) {
initializeApp(firebaseConfig);
}

export default firebase;


// import firebase from 'firebase';
// import { firebaseConfig } from 'src/config';

// // if (!firebase.apps.length) {
// //   firebase.initializeApp(firebaseConfig);
// // }

// const app = firebase.initializeApp(firebaseConfig);

// // Subsequent queries will use persistence, if it was enabled successfully

// async function getUser() {
//     try {
//         let user = await app.firestore().collection('users').doc('00012213-e40e-4ab4-a96a-f4c7f84fa233').get();
//         console.log(user);
//     } catch (err) {
//         console.log(err);
//     }
// };

// // // getUser();
// // async function createSchool() {
// //     try {
// //         let now = new Date();
// //         let schoolData = {
// //             // id: '', // firebase uuid
// //             code : 'HN0001',
// //             name: 'THCS Khương Đình',
// //             icon: '/static/images/avatars/logoblue4k-01-01.png',
// //             email: 'thcskhuongdinh-hn0001@hbon.vn',
// //             address: {
// //                 name: '16 Phố Khương Trung, Khương Đình, Thanh Xuân, Hà Nội',
// //                 street: '',
// //                 ward: { id: '', name: 'Khương Đình' },
// //                 district: { id: '', name: 'Thanh Xuân' },
// //                 city: { id: '', name: 'Hà Nội' },
// //             },
// //             phone: '02435584433',
// //             updated_at: now,
// //             created_at: now,
// //         };

// //         let newSchool = await app.firestore().collection('schools').add(schoolData);
// //     } catch (err) {
// //         console.log(err);
// //     }
// // };


// export default app;
