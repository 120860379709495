import React from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuth from '../hooks/useAuth';

import axios from 'axios';
import { API_URL } from 'src/config';
const HbonGuard = ({ children }) => {
  const { isAuthenticated,user,logout } = useAuth();
  
  if (!isAuthenticated) {
    return <Redirect to="/login" />;
  }
  
  if(user && user.roles ){
    if(user.roles.some(el => el === "hbonadmin")){
      return (
        <>
          {children}
        </>
      );
    } else if(user.roles.some(el => el === "schooladmin")){
      return <Redirect to="/school/management/classes" />;
    } else if(user.roles.some(el => el === "teacher")){
      return <Redirect to="/teacher/management/classes" />;
    }
  } else if("id_token" in user){
    var param = {
      token: user.id_token
    };
    //renew idToken
    axios.post(`${API_URL}/api/renewToken`,param);
    
    return (
      <>
        {children}
      </>
    );
  } else {
    console.log("role error")
      return <Redirect to="/404" />;
  }
  
};

HbonGuard.propTypes = {
  children: PropTypes.node
};

export default HbonGuard;
