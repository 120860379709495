import React from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuth from '../hooks/useAuth';

const GuestGuard = ({ children }) => {
  const { isAuthenticated,user } = useAuth();
console.log("GuestGuard-isAuthenticated:"+isAuthenticated)
  if (isAuthenticated) {
    console.log(user);
    return <Redirect to="/hbon/management/schools" />;
  } else {

    let str = window.location.hash;
    const search = str.substring(str.indexOf("?") + 1);

    let params = new URLSearchParams(search);
    
    let token = params.get('fbtoken');
    if(typeof(token)!="undefined" && token !=null){
      console.log(token)
    } else {
      return <Redirect to="/login"/>;
    }
  }

  return (
    <>
      {children}
    </>
  );
};

GuestGuard.propTypes = {
  children: PropTypes.node
};

export default GuestGuard;
