import app from './_firebase';

import { getAuth } from "firebase/auth";
const auth=getAuth();
async function createSchoolUser() {
    let newUserData = {
        // uid:'',
        email: 'nhhai.k57@gmail.com',
        emailVerified: true,
        phoneNumber: '+84935999693',
        password: '6453433',
        displayName: 'Hai Nguyen',
        photoURL: 'https://www.w3schools.com/howto/img_avatar.png',
        disabled: false
    };
    try {
        let newUser = await app.auth.createUser(newUserData);
        console.log(newUser);
        // let school  = await app.firestore().collection('users').doc(id).add(data);
        
    } catch (error) {
        console.log(error);
    }
}

async function listSchoolUsers(schoolId) {
    try {
        let querySnapshot = await app.firestore().collection('users').where("school.id","==",schoolId).get();
        const users = querySnapshot.docs.map((doc) => {
            return { id: doc.id, ...doc.data() }
          })
          return users
    } catch (err) {
        console.log(err);
    }
};
async function listAllUsers() {
    try {
        const docRef = app.firestore().collection('users').doc('0000636d-8def-4269-8b4a-fe4908344ba1');
        const snapshot = await docRef.get();
        const startAtSnapshot = app.firestore().collection('users')
                    .orderBy('userId')
                    .startAt(snapshot);
        let querySnapshot = await startAtSnapshot.limit(50).get();

        // let querySnapshot = await app.firestore().collection('users').get();
        const users = querySnapshot.docs.map((doc) => {
            return { id: doc.id, ...doc.data() }
          })
          return users
    } catch (err) {
        console.log(err);
    }
};
async function getUser(uid) {
    try {
        let user = await app.firestore().collection('users').doc(uid).get();
        return user.data();
    } catch (err) {
        console.log(err);
    }
};
async function getUserByEmail(email) {
    try {
        let querySnapshot = await app.firestore().collection('users').where("email","==",email).get();
        const users = querySnapshot.docs.map((doc) => {
            return { id: doc.id, ...doc.data() }
          })
          return users
    } catch (err) {
        console.log(err);
    }
};
async function updateUser(uid) {
    console.log("updateUser:",uid)
}
export {createSchoolUser,listSchoolUsers,listAllUsers,getUser,updateUser,getUserByEmail}

export const getUserIdToken = async() =>{
    var user = await auth.currentUser;
    const token = await user.getIdToken();
    return token ;
}