import React from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuth from '../hooks/useAuth';

const TeacherGuard = ({ children }) =>  {
  const { isAuthenticated, user, logout } = useAuth();
  if (!isAuthenticated) {
    return <Redirect to="/login"/>;
  }
  console.log(isAuthenticated)
  console.log(user)
  if (user && user.roles && user.roles.some(el => (el === "hbonadmin" || el === 'hbon_teacher'))) {
    
    return (
      <>
        {children}
      </>
    );
  } else if (user.id_token){
    //TODO verify token 
    var param = {
      token: user.id_token
    };
    //renew idToken
    // axios.post(`${API_URL}/api/renewToken`,param);
    
    return (
      <>
        {children}
      </>
    );
  }
  
  else {
    console.log('role error');
    return <Redirect to="/404"/>;
  }

};

TeacherGuard.propTypes = {
  children: PropTypes.node
};

export default TeacherGuard;
