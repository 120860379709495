import moment from 'moment';
import mock from 'src/utils/mock';

mock.onGet('/api/schools').reply(() => {
  const schools = [
    {
        id: '5e86809283e30b96d2d38537',
        icon: '/static/images/avatars/logoblue4k-01-01.png',
        province: 'HN',
        district: 'HD',
        address: '6A Đường Xuân thủy',
        email: 'school1@hbon.vn',
        isPublic: true,
        name: 'Trường THCS Demo',
        code: 'demo',
        phone: '+40 777666555',
        lms_url: 'moodledev.classon.vn',
        updatedAt: moment()
          .subtract(6, 'days')
          .subtract(8, 'hours')
          .toDate()
          .getTime()
    },
    {
        id: '5e86809283e30b96d2d38531',
        icon: 'http://thcstanmai.edu.vn/upload/29399/20190614/29598372_157940708200136_6879908999277544695_n.jpg',
        province: 'HN',
        district: 'Tan Mai',
        address: 'Số 9 Hoàng Văn Thụ',
        email: 'school2@hbon.vn',
        isPublic: true,
        name: 'Trường THCS Tân Mai',
        code: 'tanmai',
        phone: '+40 777666555',
        lms_url: 'moodle.anhnn.vn',
        updatedAt: moment()
          .subtract(8, 'days')
          .subtract(8, 'hours')
          .toDate()
          .getTime()
    },
    {
        id: '5e86809283e30b96d2d38533',
        icon: 'https://admin.longbien.edu.vn/UploadImages/html5banner/thcslongbien/assets/logo.png',
        province: 'HN',
        district: 'Long bien',
        address: 'Số 5 Lý Nam Đế',
        email: 'school3@hbon.vn',
        isPublic: true,
        name: 'Trường THCS Long Biên 39',
        code: 'longbien',
        phone: '+40 777666555',
        lms_url: 'moodle39.anhnn.vn',
        updatedAt: moment()
          .subtract(1, 'days')
          .subtract(8, 'hours')
          .toDate()
          .getTime()
    },
  ];
return [200, { schools }];
});


mock.onGet('/api/schools/1').reply(() => {
  const school = {
    id: '5e86809283e30b96d2d38537',
    icon: '/static/images/avatars/logoblue4k-01-01.png',
    province: 'HN',
    district: 'HD',
    address: '6A Đường Xuân thủy',
    email: 'school1@hbon.vn',
    isPublic: true,
    name: 'Trường THCS Demo',
    code: 'demo',
    phone: '+40 777666555',
    lms_url: 'http://moodle39.anhnn.vn',
    updatedAt: moment()
      .subtract(6, 'days')
      .subtract(8, 'hours')
      .toDate()
      .getTime()
  };

  return [200, { school }];
});
