import React, {
  Suspense,
  Fragment,
  lazy
} from 'react';
import {
  Switch,
  Redirect,
  Route
} from 'react-router-dom';
import HbonLayout from 'src/layouts/HbonLayout';
import TeacherLayout from 'src/layouts/TeacherLayout';
import HbonTeacherLayout from 'src/layouts/HbonTeacherLayout';
import SchoolLayout from 'src/layouts/SchoolLayout';
import DashboardLayout from 'src/layouts/DashboardLayout';
import HbonCtvLayout from 'src/layouts/HbonCtvLayout';
import DocsLayout from 'src/layouts/DocsLayout';
import MainLayout from 'src/layouts/MainLayout';
import FlutterLayout from 'src/layouts/FlutterLayout';
import LoadingScreen from 'src/components/LoadingScreen';
import AuthGuard from 'src/components/AuthGuard';
import HbonGuard from 'src/components/HbonGuard';
import SchoolGuard from 'src/components/SchoolGuard';
import HbonCtvGuard from 'src/components/HbonCtvGuard';
import GuestGuard from 'src/components/GuestGuard';
import GuestView from 'src/views/home/HomeView/school_management';
import TeacherGuard from './components/TeacherGuard';
import HbonTeacherGuard from './components/HbonTeacherGuard';

export const renderRoutes = (routes = []) => (
  <Suspense fallback={<LoadingScreen/>}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;
        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={(props) => (
              <Guard>
                <Layout>
                  {route.routes
                    ? renderRoutes(route.routes)
                    : <Component {...props} />}
                </Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
);

const routes = [
  {
    exact: true,
    path: '/404',
    component: lazy(() => import('src/views/errors/NotFoundView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/login',
    component: lazy(() => import('src/views/auth/LoginView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/schools',
    component: lazy(() => import('src/views/schools'))
  },
  {
    exact: true,
    path: '/login-unprotected',
    component: lazy(() => import('src/views/auth/LoginView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/register',
    component: lazy(() => import('src/views/auth/RegisterView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/school/signup',
    component: lazy(() => import('src/views/signup/SchoolSignup'))
  },
  {
    path: '/news_detail',
    layout: MainLayout,
    component: lazy(() => import('src/views/news/NewsDetailView'))
  },
  {
    path: '/flutter',
    layout: FlutterLayout,
    guard: TeacherGuard,
    routes: [
      {
        exact: true,
        path: '/flutter/quizzes/create/:catalogId/:sectionIndex',
        component: lazy(() => import('src/views/flutter/quizzes/QuizCreateView'))
      },
      {
        exact: true,
        path: '/flutter/catalog/view/:catalogId',
        component: lazy(() => import('src/views/teachers/catalogs/CatalogView'))
      },
      {
        exact: true,
        path: '/flutter/quizzes/edit/:quizId',
        component: lazy(() => import('src/views/flutter/quizzes/QuizEditView'))
      },
      {
        exact: true,
        path: '/flutter/catalogs/create',
        component: lazy(() => import('src/views/flutter/catalogs/CatalogCreateView'))
      },
      {
        exact: true,
        path: '/flutter/catalogs/edit/:catalogId',
        component: lazy(() => import('src/views/flutter/catalogs/CatalogEditView'))
      },
      {
        exact: true,
        path: '/flutter/catalogs/view/:catalogId',
        component: lazy(() => import('src/views/flutter/catalogs/CatalogView'))
      },
      {
        exact: true,
        path: '/flutter/theory/create/:catalogId/:sectionIndex',
        component: lazy(() => import('src/views/flutter/theory/TheoryCreateView'))
      },
      {
        exact: true,
        path: '/flutter/theory/edit/:theoryId',
        component: lazy(() => import('src/views/flutter/theory/TheoryEditView'))
      },
      {
        exact: true,
        path: '/flutter/theory/list',
        component: lazy(() => import('src/views/flutter/theory/TheoryListView'))
      },
    ]},
  // {
  //   exact: true,
  //   guard: GuestGuard,
  //   path: '/student/signup',
  //   component: lazy(() => import('src/views/signup/StudentSignup'))
  // },
  {
    exact: true,
    path: '/register-unprotected',
    component: lazy(() => import('src/views/auth/RegisterView'))
  },
  {
    path: '/teacher',
    guard: TeacherGuard,
    layout: TeacherLayout,
    routes: [
      {
        exact: true,
        path: '/teacher/management/groups/create',
        component: lazy(() => import('src/views/groups/GroupCreateView'))
      },
      {
        exact: true,
        path: '/teacher/management/groups',
        component: lazy(() => import('src/views/groups/GroupsListView'))
      },
      {
        exact: true,
        path: '/teacher/management/groups/edit/:groupId',
        component: lazy(() => import('src/views/groups/GroupEditView'))
      },
      {
        exact: true,
        path: '/teacher/management/groups/view_member/:groupId',
        component: lazy(() => import('src/views/groups/GroupListMemberView'))
      },
      {
        exact: true,
        path: '/teacher/management/classes',
        component: lazy(() => import('src/views/teachers/classes/ClassListView'))
      },
      {
        exact: true,
        path: '/teacher/management/classes/edit/:classId',
        component: lazy(() => import('src/views/teachers/classes/ClassEditView'))
      },
      {
        exact: true,
        path: '/teacher/management/classes/view_member/:classId',
        component: lazy(() => import('src/views/teachers/classes/ClassListMemberVỉew'))
      },
      {
        exact: true,
        path: '/teacher/management/livestream/create',
        component: lazy(() => import('src/views/livestream/LivestreamCreateView'))
      },
      {
        exact: true,
        path: '/teacher/management/livestream',
        component: lazy(() => import('src/views/livestream/LivestreamListView'))
      },
      {
        exact: true,
        path: '/teacher/management/livestream/:videoId/edit',
        component: lazy(() => import('src/views/livestream/LivestreamEditView'))
      },
      {
        exact: true,
        path: '/teacher/management/livestream/:videoId/live',
        component: lazy(() => import('src/views/livestream/LivestreamView'))
      },
      {
        exact: true,
        path: '/teacher/management/h5p/create',
        component: lazy(() => import('src/views/h5p/H5pCreateView'))
      },
      {
        exact: true,
        path: '/teacher/management/h5p',
        component: lazy(() => import('src/views/h5p/H5pTeacherView'))
      },
      {
        exact: true,
        path: '/teacher/management/h5p/edit/:videoId',
        component: lazy(() => import('src/views/h5p/H5pEditView'))
      },
      {
        exact: true,
        path: '/teacher/management/quizzes/create',
        component: lazy(() => import('src/views/teachers/quizzes/QuizCreateView'))
      },
      {
        exact: true,
        path: '/teacher/management/quizzes/edit/:quizId',
        component: lazy(() => import('src/views/teachers/quizzes/QuizEditView'))
      },
      {
        exact: true,
        path: '/teacher/management/quizzes',
        component: lazy(() => import('src/views/teachers/quizzes/QuizListView'))
      },
      {
        exact: true,
        path: '/teacher/management/catalogs/create',
        component: lazy(() => import('src/views/teachers/catalogs/CatalogCreateView'))
      },
      {
        exact: true,
        path: '/teacher/management/catalogs/edit/:catalogId',
        component: lazy(() => import('src/views/teachers/catalogs/CatalogEditView'))
      },
      {
        exact: true,
        path: '/teacher/management/catalogs',
        component: lazy(() => import('src/views/teachers/catalogs/CatalogListView'))
      },
      {
        exact: true,
        path: '/teacher/management/catalog/view/:catalogId',
        component: lazy(() => import('src/views/teachers/catalogs/CatalogView'))
      },{
        exact: true,
        path: '/teacher/management/newcatalog/view/:catalogId',
        component: lazy(() => import('src/views/teachers/catalogs/NewCatalogView'))
      },
      {
        exact: true,
        path: '/teacher/management/slides',
        component: lazy(() => import('src/views/teachers/slides/SlideListView'))
      },
      {
        exact: true,
        path: '/teacher/slides/present/:slideId',
        component: lazy(() => import('src/views/teachers/slides/SlidePresent'))
      },
      {
        exact: true,
        path: '/teacher/management/slides/create',
        component: lazy(() => import('src/views/teachers/slides/SlideCreateView'))
      },{
        exact: true,
        path: '/teacher/management/slides/edit/:slideId',
        component: lazy(() => import('src/views/teachers/slides/SlideEditView'))
      },
    ]
  },
  {
    path: '/hbon',
    guard: HbonGuard,
    layout: HbonLayout,
    routes: [
      {
        exact: true,
        path: '/hbon/management/roles',
        component: lazy(() => import('src/views/roles/RoleListView'))
      },
      {
        exact: true,
        path: '/hbon/management/roles/create',
        component: lazy(() => import('src/views/roles/RoleCreateView'))
      },
      {
        exact: true,
        path: '/hbon/management/roles/edit/:roleId',
        component: lazy(() => import('src/views/roles/RoleEditView'))
      },
      {
        exact: true,
        path: '/hbon/management/schools',
        component: lazy(() => import('src/views/schools/SchoolListView'))
      },
      {
        exact: true,
        path: '/hbon/management/school/:schoolId/edit',
        component: lazy(() => import('src/views/schools/SchoolEditView'))
      },
      {
        exact: true,
        path: '/hbon/management/school/:schoolId/create',
        component: lazy(() => import('src/views/schools/SchoolCreateView'))
      },
      {
        exact: true,
        path: '/hbon/management/products',
        component: lazy(() => import('src/views/product/ProductListView'))
      },
      {
        exact: true,
        path: '/hbon/management/products/create',
        component: lazy(() => import('src/views/product/ProductCreateView'))
      },
      {
        exact: true,
        path: '/hbon/management/contents',
        component: lazy(() => import('src/views/contents/ContentListView'))
      },
      {
        exact: true,
        path: '/hbon/management/livecontents',
        component: lazy(() => import('src/views/contents/ContentLiveListView'))
      },
      {
        exact: true,
        path: '/hbon/management/contents/create',
        component: lazy(() => import('src/views/contents/ContentCreateView'))
      },
      {
        exact: true,
        path: '/hbon/management/contents/createlive',
        component: lazy(() => import('src/views/contents/ContentLiveCreateView'))
      },
      {
        exact: true,
        path: '/hbon/management/contents/edit/:contentId',
        component: lazy(() => import('src/views/contents/ContentEditView'))
      },
      {
        exact: true,
        path: '/hbon/management/livecontents/edit/:contentId',
        component: lazy(() => import('src/views/contents/ContentLiveEditView'))
      },
      {
        exact: true,
        path: '/hbon/management/users',
        component: lazy(() => import('src/views/users/UserListView'))
      },
      {
        exact: true,
        path: '/hbon/management/users/create_student',
        component: lazy(() => import('src/views/users/StudentCreateView'))
      },
      {
        exact: true,
        path: '/hbon/management/users/edit/:userId',
        component: lazy(() => import('src/views/users/UserEditView'))
      },
      {
        exact: true,
        path: '/hbon/management/mentors',
        component: lazy(() => import('src/views/mentors/MentorListView'))
      },
      {
        exact: true,
        path: '/hbon/management/mentors/create',
        component: lazy(() => import('src/views/mentors/MentorCreateView'))
      },
      {
        exact: true,
        path: '/hbon/management/mentors/edit/:mentorId',
        component: lazy(() => import('src/views/mentors/MentorEditView'))
      },
      {
        exact: true,
        path: '/hbon/management/teachers',
        component: lazy(() => import('src/views/teachers/TeacherListView'))
      },
      {
        exact: true,
        path: '/hbon/management/teachers/create',
        component: lazy(() => import('src/views/teachers/TeacherCreateView'))
      },
      {
        exact: true,
        path: '/hbon/management/teachers/edit/:mentorId',
        component: lazy(() => import('src/views/teachers/TeacherEditView'))
      },
      {
        exact: true,
        path: '/hbon/management/ads',
        component: lazy(() => import('src/views/ads/AdListView'))
      },
      {
        exact: true,
        path: '/hbon/management/ads/create',
        component: lazy(() => import('src/views/ads/AdCreateView'))
      },
      {
        exact: true,
        path: '/hbon/management/ads/edit/:adId',
        component: lazy(() => import('src/views/ads/AdEditView'))
      },
      {
        exact: true,
        path: '/hbon/management/quiz/create',
        component:lazy(()=>import('src/views/quiz/QuizCreateView'))
      },
      {
        exact: true,
        path: '/hbon/management/sendnotify/create',
        component:lazy(()=>import('src/views/sendnotify/QuizTestCreateView'))
      },
      {
        exact: true,
        path: '/hbon/management/sendnotify/list',
        component:lazy(()=>import('src/views/sendnotify/QuizTestListView'))
      },
      {
        exact: true,
        path: '/hbon/management/sendnotify/edit/:QuizTestId',
        component:lazy(()=>import('src/views/sendnotify/QuizTestEditView'))
      },
      {
        exact: true,
        path: '/hbon/management/pre_contents',
        component: lazy(() => import('src/views/prepost_contents/ContentListView'))
      },
      {
        exact: true,
        path: '/hbon/management/pre_contents/create',
        component: lazy(() => import('src/views/prepost_contents/ContentCreateView'))
      },
      {
        exact: true,
        path: '/hbon/management/pre_contents/edit/:contentId',
        component: lazy(() => import('src/views/prepost_contents/ContentEditView'))
      },
      {
        exact: true,
        path: '/hbon/management/h5p/create',
        component: lazy(() => import('src/views/h5p/H5pCreateView'))
      },
      {
        exact: true,
        path: '/hbon/management/h5p',
        component: lazy(() => import('src/views/h5p/H5pListView'))
      },
      {
        exact: true,
        path: '/hbon/management/h5p/edit/:videoId',
        component: lazy(() => import('src/views/h5p/H5pEditView'))
      },
      {
        exact: true,
        path: '/hbon/management/events/submit/:eventId',
        component: lazy(() => import('src/views/events/EventListSubmitView'))
      },{
        exact: true,
        path: '/hbon/management/events',
        component: lazy(() => import('src/views/events/EventListView'))
      },
      {
        exact: true,
        path: '/hbon/management/events/create',
        component: lazy(() => import('src/views/events/EventCreateView'))
      },
      {
        exact: true,
        path: '/hbon/management/events/edit/:eventId',
        component: lazy(() => import('src/views/events/EventEditView'))
      },
    ]
  },
  {
    path:'/hbonctv',
    guard:HbonCtvGuard,
    layout:HbonCtvLayout,
    routes:[
      {
        exact: true,
        path: '/hbonctv/dashboard',
        component: lazy(() => import('src/views/hbonctv/automation/AutoCreateView'))
      },
      {
        exact: true,
        path: '/hbonctv/popup',
        component: () => {
          window.location.href = 'https://hocbaionha.com/local/sm/home_popup_management.php?token_auto=o91xiy8qod1o4ebsyv035y5wssn6jmb493bd7hrn901yreninwsgvns49f69nmai';
          return null;
        }
      },{
        exact: true,
        path: '/hbonctv/management/contents/createlive',
        component: lazy(() => import('src/views/contents/ContentLiveCreateView'))
      },
      {
        exact: true,
        path: '/hbonctv/management/livecontents',
        component: lazy(() => import('src/views/contents/ContentLiveListView'))
      },
      {
        exact: true,
        path: '/hbonctv/management/livecontents/edit/:contentId',
        component: lazy(() => import('src/views/contents/ContentLiveEditView'))
      },
    ]
  },
  {
    path: '/school',
    guard: SchoolGuard,
    layout: SchoolLayout,
    routes: [
      {
        exact: true,
        path: '/school/management/init_new_years',
        component: lazy(() => import('src/views/schools/InitNewYear'))
      }, {
        exact: true,
        path: '/school/management/classes/',
        component: lazy(() => import('src/views/classes/ClassListView'))
      },
      {
        exact: true,
        path: '/school/management/classes/create',
        component: lazy(() => import('src/views/classes/ClassCreateView'))
      },
      {
        exact: true,
        path: '/school/management/classes/edit/:classId',
        component: lazy(() => import('src/views/classes/ClassEditView'))
      },
      {
        exact: true,
        path: '/school/management/courses/',
        component: lazy(() => import('src/views/courses/CourseListView'))
      },
      {
        exact: true,
        path: '/school/management/courses/create',
        component: lazy(() => import('src/views/courses/CourseCreateView'))
      },
      {
        exact: true,
        path: '/school/management/students/create',
        component: lazy(() => import('src/views/student/StudentCreateView'))
      },
      {
        exact: true,
        path: '/school/management/students',
        component: lazy(() => import('src/views/student/StudentListView'))
      },
      {
        exact: true,
        path: '/school/management/students/edit/:studentId',
        component: lazy(() => import('src/views/student/StudentEditView'))
      },
      {
        exact: true,
        path: '/school/management/bought_students',
        component: lazy(() => import('src/views/bought_student/BoughtStudentListView'))
      },
      {
        exact: true,
        path: '/school/management/bought_students/enrole/:studentId',
        component: lazy(() => import('src/views/bought_student/BoughtStudentEnrole'))
      },
      {
        exact: true,
        path: '/school/management/contents',
        component: lazy(() => import('src/views/schools/contents/ContentListView'))
      },
      {
        exact: true,
        path: '/school/management/contents/create',
        component: lazy(() => import('src/views/schools/contents/ContentCreateView'))
      },
      {
        exact: true,
        path: '/school/management/contents/edit/:contentId',
        component: lazy(() => import('src/views/schools/contents/ContentEditView'))
      },
      {
        exact: true,
        path: '/school/management/events/submit/:eventId',
        component: lazy(() => import('src/views/schools/events/EventListSubmitView'))
      },{
        exact: true,
        path: '/school/management/events',
        component: lazy(() => import('src/views/schools/events/EventListView'))
      },
      {
        exact: true,
        path: '/school/management/events/create',
        component: lazy(() => import('src/views/schools/events/EventCreateView'))
      },
      {
        exact: true,
        path: '/school/management/events/edit/:eventId',
        component: lazy(() => import('src/views/schools/events/EventEditView'))
      },
    ]
  },
  {
    path: '/hbon_teacher',
    guard: HbonTeacherGuard,
    layout: HbonTeacherLayout,
    routes: [

      {
        exact: true,
        path: '/hbon_teacher/management/quizzes/create',
        component: lazy(() => import('src/views/hbon/quizzes/QuizCreateView'))
      },
      {
        exact: true,
        path: '/hbon_teacher/management/quizzes/edit/:quizId',
        component: lazy(() => import('src/views/hbon/quizzes/QuizEditView'))
      },
      {
        exact: true,
        path: '/hbon_teacher/management/quizzes',
        component: lazy(() => import('src/views/hbon/quizzes/QuizListView'))
      },
    ]
  },

  {
    path: '*',
    layout: MainLayout,
    routes: [
      {
        exact: true,
        path: '/',
        component: GuestView
      },
      {
        component: () => <Redirect to="/404"/>
      }
    ]
  }
];

export default routes;
