export const auth0Config = {
    client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
    domain: process.env.REACT_APP_AUTH0_DOMAIN
};

export const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  api_url:process.env.REACT_APP_SERVER_URL
};

// export const firebaseConfig = {
//     apiKey: 'AIzaSyD1rFzhNHcbWql0L8g4dWMcdgn5KiZ3cv4',
//     appId: '1:666303282505:web:2daabfe2056b8bcf276e98',
//     authDomain: 'hbon-sso.firebaseapp.com',
//     databaseURL: 'https://hbon-sso.firebaseio.com',
//     messagingSenderId: '666303282505',
//     projectId: 'hbon-sso',
//     storageBucket: 'hbon-sso.appspot.com'
// };

export const moodleWSConfig = {
    token: process.env.MOODLE_API_KEY,
}

// export const API_URL = process.env.NODE_ENV === 'production' ? 'https://server.dschool.vn': 'https://localhost:5000'
// export const API_URL = 'https://server.dschool.vn';
export const API_URL = process.env.REACT_APP_SERVER_URL;//'https://localhost:5000';
export const LOGOUT_URL = process.env.REACT_APP_LOGOUT_URL;//'https://id-dev.hocbaionha.com/oauth2/sessions/logout';,
export const H5P_SERVER_URL = process.env.REACT_APP_H5P_URL;
