import React from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuth from '../hooks/useAuth';

const SchoolGuard = ({ children }) => {
  const { isAuthenticated,user } = useAuth();
  if (!isAuthenticated) {
    return <Redirect to="/login" />;
  }
  if(user && user.roles && user.roles.some(el => (el === "hbonadmin" || el === "schooladmin"))){
    console.log("hbonadmin")
    if(user.roles.some(el => (el === "hbonadmin"))){
      // admin can choose school
    } else {
      localStorage.setItem('schoolid', user.school_id);
    }
    //TODO validate user school  admin here
    return (
        <>
          {children}
        </>
      );
  } else {
    console.log("error")
    console.log(user.roles)
      return <Redirect to="/404" />;
  }
  
};

SchoolGuard.propTypes = {
  children: PropTypes.node
};

export default SchoolGuard;
