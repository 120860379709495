import React,{ createContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import firebase from '../lib/firebase';
// import firebase from 'src/api/_firebase';

import SplashScreen from 'src/components/SplashScreen';
import {getUser} from 'src/api/users';
import axios from 'axios';
import { API_URL,LOGOUT_URL } from 'src/config';
const initialState = {
  isAuthenticated: false,
  isInitialised: false,
  user: null
};

const reducer = (state, action) => {
  switch (action.type) {
      case 'AUTH_STATE_CHANGED': {
          const { isAuthenticated, user } = action.payload;

          return {
              ...state,
              isAuthenticated,
              isInitialised: true,
              user
          };
      }
      case 'LOGIN': {
          const { user } = action.payload;
          return {
              ...state,
              isAuthenticated: true,
              user
          };
      }

      case 'LOGOUT': {
          return {
              ...state,
              isAuthenticated: false,
              user: null
          };
      }
      default: {
          return { ...state };
      }
  }
};
// const reducer = (state, action) => {
//   if (action.type === 'AUTH_STATE_CHANGED') {
//     const { isAuthenticated, user } = action.payload;

//     return {
//       ...state,
//       isAuthenticated,
//       isInitialized: true,
//       user
//     };
//   }

//   return state;
// };

const AuthContext = createContext({
  ...initialState,
  platform: 'Firebase',
  method: 'FirebaseAuth',
  createUserWithEmailAndPassword: () => Promise.resolve(),
  signInWithEmailAndPassword: () => Promise.resolve(),
  signInWithGoogle: () => Promise.resolve(),
  signInWithFacebook: () => Promise.resolve(),
  logout: () => Promise.resolve()
});

export const AuthProvider = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);
  console.log(state)
  console.log("1")

  const doLogin = async (user)=>{
    if(!user) return;
    let idToken="";
        console.log(user)
        let token = user.firebaseToken;
        let uid = user.uid;
        if(typeof(token)!="undefined"){
            console.log("auth...")
            if(token){
                await firebase.auth().signInWithCustomToken(token).then(()=>{
                  console.log("auth success")
                  
                }).catch(function(error) {
                    var errorCode = error.code;
                    var errorMessage = error.message;
                    console.log("errC",errorCode);
                    console.log("errM",errorMessage);
                });
                idToken = await firebase.auth().currentUser.getIdToken();
                    console.log(idToken)
              }
        } else {
            console.log("not found token")
        }
        user = await getUser(uid)
        if(user){
            if(!user.name && user.displayname){
                user.name = user.displayname
            }
            user.id_token = idToken;
        };
        
}
  const verifyToken = async() => {
    let str = window.location.hash;
    const search = str.substring(str.indexOf("?") + 1);

    let params = new URLSearchParams(search);
    
    let token = params.get('fbtoken');
    if(typeof(token)!="undefined" && token !=null){
        console.log("auth...",token)
        if(token){
            var param = {
                token: token
              };
            //   //renew idToken
            let result = await  axios.post(`${API_URL}/api/renewToken`,param);
            let customToken=result.data.customToken;
            let user = await firebase.auth().signInWithCustomToken(customToken).user;
            console.log("firebase signin success")
            // doLogin(user);
        }
        return token;
    } else {
      return false;
    }
    
  }
  useEffect( () =>
     { async function fetchData() {
      let token = await verifyToken();
      console.log("verify:",token)
       firebase.auth().onAuthStateChanged(async (fbuser) => {
      
          
     console.log("2")
     console.log(fbuser);
     if (fbuser) {
       
       let user = await getUser(fbuser.uid);
       console.log("login success",user)
       let id_token= (await fbuser.getIdToken()).toString();
       console.log(id_token)
       if(user.name && user.displayname){
         user.name = user.displayname
     }
       // Here you should extract the complete user profile to make it available in your entire app.
       // The auth state only provides basic information.
       localStorage.setItem('user', JSON.stringify({id_token:id_token,roles:user.roles,id:fbuser.uid,name:user.name}));
       console.log("3")
       dispatch({
         type: 'AUTH_STATE_CHANGED',
         payload: {
           isAuthenticated: true,
           user: user
         }
       });
     } else if(!state.initialState && !token) {
       console.log("4")
       dispatch({
         type: 'AUTH_STATE_CHANGED',
         payload: {
           isAuthenticated: false,
           user: null
         }
       });
     }
   })
  }
  fetchData();
  
  }
, [dispatch]);
  
  const signInWithEmailAndPassword = (email,
    password) => firebase.auth().signInWithEmailAndPassword(email, password);

  const signInWithGoogle = () => {
    const provider = new firebase.auth.GoogleAuthProvider();

    return firebase.auth().signInWithPopup(provider);
  };
  const signInWithFacebook = () => {
    const provider = new firebase.auth.FacebookAuthProvider();

    return firebase.auth().signInWithPopup(provider);
  };

  const createUserWithEmailAndPassword = async (email,
    password) => firebase.auth().createUserWithEmailAndPassword(email, password);

  const logout = async () => {
    await firebase.auth().signOut();
  };

  if (!state.isInitialised) {
    return <SplashScreen />;
}

  return (
    <AuthContext.Provider
      value={{
        ...state,
        platform: 'Firebase',
        method: 'FirebaseAuth',
        createUserWithEmailAndPassword,
        signInWithEmailAndPassword,
        signInWithGoogle,
        signInWithFacebook,
        logout
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default AuthContext;
