import React from 'react';
import Page from 'src/components/Page';
import {
  Box,
  Container,
  Grid,
  Typography,
  makeStyles
} from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {}
}));

const GuestView = () => {
  const classes = useStyles();

  return (
    <Page
      className={classes.root}
      title="Home"
    >
      <div className="home-container">
      <Container maxWidth="lg">
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            xs={12}
            md={5}
          >
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              height="100%"
            >
              <Typography
                variant="overline"
                color="secondary"
              >
                Giới thiệu
              </Typography>
              <Typography
                variant="h1"
                color="textPrimary"
              >
                Hệ thống quản lý học tập 
              </Typography>
              <Box mt={3}>
                <Typography
                  variant="body1"
                  color="textSecondary"
                >
                  Giúp học sinh, giáo viên, phụ huynh dễ dàng theo dõi quá trình học tập 
                  các khóa đang học, tiến độ học tập,
                  các bài tập được giao, các bài tập đã hoàn thành, điểm số ...
                  <br></br>
                  Hệ thống đã kết nối với:
                </Typography>
              </Box>
              <Box mt={3}>
                <Grid
                  container
                  spacing={3}
                >
                  <Grid item>
                    <Typography
                      variant="h1"
                      color="secondary"
                    >
                      80+
                    </Typography>
                    <Typography
                      variant="overline"
                      color="textSecondary"
                    >
                      Trường THCS
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      variant="h1"
                      color="secondary"
                    >
                      
                    </Typography>
                    <Typography
                      variant="overline"
                      color="textSecondary"
                    >
                      
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      variant="h1"
                      color="secondary"
                    >
                      300.000+
                    </Typography>
                    <Typography
                      variant="overline"
                      color="textSecondary"
                    >
                      Học sinh
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
              <Box mt={3}>
                
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={7}
          >
            <Box position="relative">
              <img alt="hbon" src="/hbon/ltv10-student.webp" className="home-image1"/>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </div>
    </Page>
  );
};

export default GuestView;
